import type { LocationQuery } from 'vue-router'
import isBot from '~/lib/isBot.js'
import { COOKIES } from '~/constants'

export default function (slug: string, query: LocationQuery) {
  const rtSessionId = useCookie(COOKIES.session)
  const headers = useRequestHeaders(['User-Agent'])
  const localePath = useLocalePath()

  const reqWithRts = generateRvPath(slug, query)

  if (!isBot(headers['user-agent'])) {
    if (!reqWithRts.query) {
      reqWithRts.query = {}
    }
  }

  return localePath({
    name: reqWithRts.name,
    params: reqWithRts.params,
    query: {
      ...reqWithRts.query,
      rts: rtSessionId.value,
    },
  })
}

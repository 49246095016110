<template>
  <div
    :class="computedClasses"
    :style="cssVars"
  >
    <slot
      :visible-results="visibleResults"
      :load-more="loadNextCards"
      :queue-viewable-impression="queueViewableImpression"
    />
    <div
      v-if="showNoResults"
      class="no-results"
    >
      {{ t('noResultsFound') }}
    </div>
  </div>
</template>

<script>
import { ImageResolution } from '~/lib/enums'
import { trackListingSetViewed } from '~/lib/tracking'
import { getNightlyRate } from '~/lib/rvs/index'
import { IMAGE_QUALITY } from '~/constants/image'
import getRvCategoryByType from '~/lib/getRvCategoryByType'

export default {
  name: 'GridList',

  props: {
    resultsLength: {
      type: Number,
      default: null,
    },

    /**
     * The total results to load for a given page.
     */
    pageSize: {
      type: Number,
      default: 5,
    },

    /**
     * The amount of results to load data for at once.
     */
    batchSize: {
      type: Number,
      default: 5,
    },

    /**
     * The amount of rows to show.
     * Default to 0: no limit.
     */
    rows: {
      type: Number,
      default: 0,
    },

    maxColumns: {
      type: Number,
      default: 5,
    },

    hideNoResults: {
      type: Boolean,
      default: false,
    },

    scrollable: {
      type: Boolean,
      default: false,
    },

    pageNumber: {
      type: Number,
      default: 1,
    },
  },

  setup() {
    const { t } = useI18n({
      useScope: 'local',
    })
    const { checkIfRvIsFavourited } = useFavouriteRVs()
    const { routeBaseName } = useBaseName()
    const { getImageUrl } = useImageUrl()

    return {
      checkIfRvIsFavourited,
      t,
      routeBaseName,
      getImageUrl,
    }
  },

  data() {
    return {
      visiblityQueue: [],
      queueTimer: null,

      /**
       * The index used to load incremental batches of results.
       */
      batchIndex: 1,
    }
  },

  computed: {
    /**
     * The list of results that have their data displayed.
     */
    currentListOfResults() {
      const batch = this.batchIndex * this.batchSize
      return this.resultsLength !== null && this.resultsLength < batch ? this.resultsLength : batch
    },

    /**
     * Returns either an empty list of results with pregenerated ids, or the actual
     * list of results mapped to those pregenerated ids. This is done so that the
     * keys in the list will never change and the DOM tracks updates correctly
     * instead of re-rendering the card when the results are loaded.
     */
    visibleResults() {
      return [...Array(this.currentListOfResults)].map((_, i) => ({ pregen_id: `someid-${i}` }))
    },

    showNoResults() {
      return !this.hideNoResults && this.noResults
    },

    noResults() {
      return this.resultsLength === 0
    },

    /**
     * Determines if the grid layout should use auto rows, or fixed rows.
     *
     * Even when we use multiple rows, we want to use "auto" rows when there's
     * not enough data to display. This is quite a crude approach, but will work
     * for now.
     */
    shoulduseAutoRows() {
      return this.resultsLength !== null && this.resultsLength <= this.maxColumns && (this.rows === 0 || this.rows > 1)
    },

    computedClasses() {
      return ['grid-list', `max-${this.maxColumns}`, { empty: this.showNoResults, scrollable: this.scrollable }]
    },

    /**
     * The CSS variables for the grid layout.
     */
    cssVars() {
      return {
        '--rows': !this.shoulduseAutoRows && this.rows > 0 ? `repeat(${this.rows}, 1fr)` : 'auto',
        '--auto-rows': !this.shoulduseAutoRows && this.rows > 0 ? '0' : 'auto',
      }
    },
  },

  beforeUnmount() {
    clearTimeout(this.queueTimer)
    this.trackViewableImpressions()
  },

  methods: {
    /**
     * Incrementally loads the next set of cards by increasing the index, but
     * only if the page limit hasn't been reached yet..
     */
    loadNextCards(index) {
      if (index === this.visibleResults.length - 1 && this.currentListOfResults < this.pageSize) {
        this.batchIndex++
      }
    },

    queueViewableImpression({ rv, requestId, cta }) {
      this.visiblityQueue.push(rv)
      // reset the timer everytime something comes into the queue
      clearTimeout(this.queueTimer)
      this.queueTimer = setTimeout(() => {
        this.trackViewableImpressions(requestId, cta)
      }, 5000)
    },

    trackViewableImpressions(requestId, cta) {
      const queueToSend = [...this.visiblityQueue]
      this.visiblityQueue = []

      if (queueToSend.length) {
        trackListingSetViewed({
          params: {
            cta: cta,
            listingPageNumber: this.pageNumber,
            listingPageSize: this.pageSize,
            pageSource: this.routeBaseName,
            listings: queueToSend.map((rv, index) => ({
              heroImage: this.getImageUrl({
                path: rv?.Photos?.[0]?.Path,
                resolution: ImageResolution.LARGE,
                quality: IMAGE_QUALITY.medium,
              }),
              isFavourite: Boolean(this.checkIfRvIsFavourited(rv.Id)),
              nightlyRate: getNightlyRate(rv) / (1 + (rv?.SmartPricingPercentage ?? 0)),
              smartNightlyRate: rv?.SmartPricingPercentage > 0 ? getNightlyRate(rv) : undefined,
              rvName: rv.RVName,
              rvId: rv.Id,
              currency: lookupCountryCodeToCurrencyCode(rv.Country),
              listingType: rv.RVType,
              distanceKm: rv.Distance,
              listPosition: index + 1,
              distanceUnit: countryCodeToDistanceUnitShort(rv.Country),
              hasDelivery: rv.HasDelivery,
              listingCountry: rv.Country,
              listingRegion: rv.State,
              listingCity: rv.City,
              isFeatured: rv.IsFeatured,
              isInstantBook: rv.InstabookOwnerOptedIn,
              numReviews: rv.NumberOfReview,
              reviewsShown: rv.NumberOfReview > 0,
              rentalType: getRvCategoryByType(rv.RVType) || 'Unknown',
              starRating: rv.AverageRating,
              listingSleepingSpots: rv.Guests,
            })),
          },
          additionalParameters: {
            rvUrlListings: queueToSend.map((rv) => {
              return { rvUrl: useAbsoluteUrl(generateRvPath(rv.AliasName)) }
            }),
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
.grid-list > * {
  margin-bottom: 2rem;
}

.grid-list.scrollable > * {
  &.rv-card {
    @include media-max-size(xLarge) {
      min-width: 215px;
    }
  }
}
</style>

<style lang="scss" scoped>
.grid-list {
  --columns: 1;
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  column-gap: 1rem;
  grid-template-rows: var(--rows);
  grid-auto-rows: var(--auto-rows);

  @include media-min-size(small) {
    --columns: 2;
  }

  @include media-min-size(medium) {
    --columns: 3;
  }

  @include media-min-size(large) {
    --columns: 4;
    &.max-4 {
      --columns: 3;
    }
  }

  @include media-min-size(xLarge) {
    --columns: 5;
    &.max-4 {
      --columns: 4;
    }
  }

  &.scrollable {
    @include media-max-size(xLarge) {
      grid-template-columns: repeat(5, 1fr);
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      overscroll-behavior-x: contain;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      > * {
        scroll-snap-align: start;
      }
    }
  }

  &.empty {
    grid-template-columns: auto;

    .no-results {
      @include semi-bold;
      border: 1px solid getColor('primary-100');
      border-radius: 8px;
      padding: 12px;
    }
  }

  & + .grid-list {
    margin-top: 2rem;
  }
}
</style>

<i18n src="~/locales/common/search/results.json" lang="json" />
